
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getBloggerRiskLabelList, deleteBloggerRiskLabel, saveBloggerRiskLabel } from "@/api/request/blogger";
import GFunc from "@/utils/gFunc";
import { Message } from "element-ui";

//组件
@Component({
  name: "PostRecommendUserList",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //页码
    page: 1,
    page_size: 20,

    //临时数据
    id_str: "", //标签ID

    //数据
    id: 0, //标签ID
    name: "", //标签名
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);

    //获取数据
    const { data } = await getBloggerRiskLabelList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private addHandler(): void {
    //数据赋值
    this.addForm = {
      id: 0,
      name: "",
      names: {
        zh: "",
      },
      color_set: {
        //颜色色码设置
        frame_color: "#000000",
        label_color: "#000000",
        select_color: "#000000",
      },
      sort: 29,
      sort_str: "",
    };

    //显示对话框
    this.dialogVisible = true;
    this.dialogStatus = "create";
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      id: row.id,
      name: row.name,
      names: {
        zh: row.names.zh,
      },
      color_set: {
        //颜色色码设置
        frame_color: row.color_set.frame_color,
        label_color: row.color_set.label_color,
        select_color: row.color_set.select_color,
      },
      sort: row.sort,
      sort_str: String(row.sort),
    };

    //显示对话框
    this.dialogVisible = true;
    this.dialogStatus = "update";
  }

  //处理删除
  private handleDelete(row: any): void {
    this.$confirm(`您确定要删除${row.name} ？`, "警告", {
      type: "warning",
      cancelButtonText: this.$t("permission.cancel") as string,
      confirmButtonText: this.$t("permission.confirm") as string,
    })
      .then(async () => {
        //删除标签
        await deleteBloggerRiskLabel({ id: row.id });

        //显示提示
        this.$message.success("删除成功");

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //------------------------------ 编辑/添加 ------------------------------
  //定义变量
  private dialogStatus: string = ""; //对话框状态
  private dialogVisible: boolean = false; //是否显示
  private dialogLoading: boolean = false; //对话框等待
  private dialogTitleMap: any = { update: "编辑标签", create: "新增标签" }; //对话框标题

  //表单数据
  private addForm: any = {
    id: 0,
    name: "",
    names: {
      zh: "",
    },
    color_set: {
      //颜色色码设置
      frame_color: "#000000",
      label_color: "#000000",
      select_color: "#000000",
    },
    sort: 29,
    sort_str: "",
  };

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //更新道具
  private async updateData() {
    //判断返回
    if (this.addForm.name == "") return Message({ message: "请输入标签名！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.sort_str) == 0) return Message({ message: "请输入排序！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //处理编辑
    await saveBloggerRiskLabel(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;
    this.dialogLoading = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //创建道具
  private async createData() {
    //判断返回
    if (this.addForm.name == "") return Message({ message: "请输入标签名！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.sort_str) == 0) return Message({ message: "请输入排序！", type: "error", duration: 5 * 1000 });

    //显示等待
    this.dialogLoading = true;

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //处理编辑
    await saveBloggerRiskLabel(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;
    this.dialogLoading = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }
}
